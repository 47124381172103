export const config = {
  api_base: 'https://prod.diningtek.com/api/v1',
  api_root : 'https://prod.diningtek.com',
  logo_img_root : '/img/pinocchios-logo.png',
  banner_img_root : '/img/pinocchios-banner.png',
  resid : '',
  key_value : 'pinocchios',
  secret_value : 'pinocchios',
  iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.8881671671243!2d-105.13438358461102!3d40.18930217939237!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876bfbe49e269693%3A0xb5485b1ee78d644f!2s1751%20Hover%20St%2C%20Longmont%2C%20CO%2080501%2C%20USA!5e0!3m2!1sen!2sin!4v1596810315575!5m2!1sen!2sin',
  facebook_link:'#',
  RestaurantName:"Pinocchios Hover St Order Online"
};
